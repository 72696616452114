const CATEGORY_1 = Object.freeze({
  line1: 'Sale ends 27 August 2019 23:59 ICT.',
  line2: 'Full-priced items on discount are eligible for return.',
  line3: 'No voucher codes may be used on discounted items.',
  line4: 'No price adjustments will be offered on discounted products.',
  line5: '',
  line6: '',
  line7: '',
  line8: '',
  line1Key: 'CATEGORY_1_TNC_1',
  line2Key: 'CATEGORY_1_TNC_2',
  line3Key: 'CATEGORY_1_TNC_3',
  line4Key: 'CATEGORY_1_TNC_4',
  line5Key: 'CATEGORY_1_TNC_5',
  line6Key: 'CATEGORY_1_TNC_6',
  line7Key: 'CATEGORY_1_TNC_7',
  line8Key: 'CATEGORY_1_TNC_8',
})

export default CATEGORY_1
