import PropTypes from 'prop-types'
import React from 'react'
import { ControlBar, Player } from 'video-react'
import clsx from 'clsx'
import ResponsiveImage from 'components/images/responsive-image'
import Icon, { ICONS } from 'components/common/icon'
import styles from './style.scss'

class VideoPlayer extends React.PureComponent {
  state = {
    isFullscreen: false,
    hasStarted: false,
  }

  videoEl = React.createRef()

  prevState = {}

  coverWrapperStyle = { cursor: 'pointer' }

  componentDidMount() {
    this.videoEl.current.subscribeToStateChange(this.onVideoStateChange)
  }

  // can't find a way to simulate video state: hasStarted
  /* istanbul ignore next */
  onVideoStateChange = (newState) => {
    const { fullscreenOnPlay } = this.props

    // Activating the video, remove the cover poster.
    if (newState.hasStarted && !this.prevState.hasStarted) {
      this.prevState.hasStarted = true
      this.setState({ hasStarted: true })

      // On fullscreen exited.
    } else if (
      fullscreenOnPlay &&
      !newState.isFullscreen &&
      this.prevState.isFullscreen
    ) {
      this.videoEl.current.video.pause()
      this.setState({ isFullscreen: false })
    }

    this.prevState = newState
  }

  onPosterClicked = () => this.videoEl.current.video.play()

  onWrapperClicked = () => {
    const { fullscreenOnPlay } = this.props

    if (fullscreenOnPlay && !this.prevState.isFullscreen) {
      this.videoEl.current.video.toggleFullscreen()
      this.setState({ isFullscreen: true })
    }
  }

  render() {
    const {
      className,
      hideControls,
      loop,
      muted,
      showPlayIcon,
      wrapperStyles,
      cover,
      source,
    } = this.props
    const { hasStarted, isFullscreen } = this.state

    return (
      <div
        className={clsx('video-player-container', className || '')}
        onClick={this.onWrapperClicked}
        style={wrapperStyles}
        role="presentation"
      >
        <style jsx>{styles}</style>
        {!hasStarted && (
          <React.Fragment>
            <ResponsiveImage
              alt="video poster"
              className="cover-image"
              imgRatio={0.5625}
              onClick={this.onPosterClicked}
              src={cover}
              wrapperStyles={this.coverWrapperStyle}
            />
            {showPlayIcon && (
              <Icon
                alt="play icon"
                size={64}
                src={ICONS.play}
                className="icon icon-play"
                onClick={this.onPosterClicked}
              />
            )}
          </React.Fragment>
        )}
        <Player
          ref={this.videoEl}
          playsInline
          loop={loop}
          muted={muted}
          preload="none"
          src={source}
        >
          <ControlBar
            className={hideControls && !isFullscreen ? 'hidden' : ''}
          />
        </Player>
      </div>
    )
  }
}

VideoPlayer.defaultProps = {
  className: '',
  cover: '',
  fullscreenOnPlay: false,
  hideControls: false,
  loop: false,
  muted: true,
  showPlayIcon: true,
  wrapperStyles: null,
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  cover: PropTypes.string,
  fullscreenOnPlay: PropTypes.bool,
  hideControls: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  showPlayIcon: PropTypes.bool,
  source: PropTypes.string.isRequired,
  wrapperStyles: PropTypes.shape({}),
}

export default VideoPlayer
