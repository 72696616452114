import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'
import NPSApiHandler from 'lib/api/nps'
import TextField from 'components/common/textfield'
import Dialog from 'components/common/dialog'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { hasLocizeTranslation } from 'lib/utils/locize'
import styles from './style.scss'
import Loader from '../loader'

class NPSForm extends React.Component {
  constructor(props) {
    super()

    this.state = {
      loading: false,
      message: null,
      responseMessage: '',
      NPSID: null,
      score: props.score,
      errorMsg: undefined,
    }

    NPSApiHandler.saveScore({
      email: props.email,
      score: props.score,
      type: props.type,
      id_reference: props.reference,
    })
      .then((res) => {
        this.setState({
          NPSID: res?.id_nps,
        })
      })
      .catch((error) => {
        this.setState({ errorMsg: error.message })
      })
  }

  updateComment = (e) => this.setState({ message: e.target.value })

  save = async () => {
    const { close } = this.props
    const { errorMsg, responseMessage } = this.state
    if (responseMessage) {
      close()
    } else {
      if (errorMsg) {
        this.setState({ errorMsg: undefined })

        return
      }

      this.setState({ loading: true }, async () => {
        const { score, message: comment, NPSID } = this.state
        try {
          await NPSApiHandler.saveComment({
            comment,
            score,
            NPSID,
          })
          this.showResponseMessage(true)
        } catch (error) {
          this.showResponseMessage(false)
        }
      })
    }
  }

  showResponseMessage(isSuccess) {
    const { close, t } = this.props

    this.setState({
      loading: false,
      responseMessage: t(
        isSuccess
          ? 'Thank you for rating us!'
          : 'Could not save information, please try again later.',
      ),
    })

    setTimeout(() => {
      close()
    }, 3000)
  }

  renderTitleIcon() {
    const { errorMsg, responseMessage } = this.state

    if (errorMsg) {
      return <Icon src={ICONS.wrong} size={ICON_SIZE.extraLarge} />
    }
    if (responseMessage) {
      return <Icon src={ICONS.wishlist} size={ICON_SIZE.extraLarge} />
    }

    return undefined
  }

  renderTitle() {
    const { t } = this.props
    const { errorMsg } = this.state

    if (errorMsg) {
      return hasLocizeTranslation(t, 'NPS_ERROR_TITLE', 'Oh oh....')
    }

    return t('Thank you for rating us! Please tell us why.')
  }

  renderConfirmText() {
    const { t } = this.props
    const { errorMsg, responseMessage } = this.state

    if (errorMsg) {
      return hasLocizeTranslation(t, 'NPS_RETRY_LABEL', 'Again')
    }
    if (responseMessage) {
      return hasLocizeTranslation(t, 'NPS_CONFIRM_LABEL', 'Confirm')
    }

    return t('Send')
  }

  renderBody() {
    const { t } = this.props
    const { errorMsg, responseMessage } = this.state

    if (errorMsg) {
      return <span className={Typo.body2}>{errorMsg}</span>
    }
    if (responseMessage) {
      return <span className={Typo.h6}>{responseMessage}</span>
    }
    return (
      <TextField
        fullWidth
        className="nps-form__input"
        multiline
        minRows={1}
        onChange={this.updateComment}
        placeholder={t('Help us by explaining your score.')}
      />
    )
  }

  render() {
    const { loading } = this.state

    return (
      <Dialog
        className="nps-form"
        icon={this.renderTitleIcon()}
        title={this.renderTitle()}
        confirmable
        confirmText={this.renderConfirmText()}
        onConfirm={this.save}
      >
        <style jsx>{styles}</style>
        {loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          this.renderBody()
        )}
      </Dialog>
    )
  }
}

NPSForm.propTypes = {
  close: PropTypes.func.isRequired,
  email: PropTypes.string,
  reference: PropTypes.string,
  score: PropTypes.string,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
}

NPSForm.defaultProps = {
  email: undefined,
  reference: undefined,
  score: undefined,
  type: undefined,
}

export default translate('global')(NPSForm)
