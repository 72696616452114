import PropTypes from 'prop-types'
import React from 'react'
import EditorialMasonry from 'components/editorial/editorial-masonry'
import EditorialVideos from 'components/editorial/editorial-videos'
import EditorialBestsellers from 'components/editorial/editorial-bestsellers'
import RecentProductList from 'components/recently-view/recent-product-list'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import InfluencerSection from './influencer-section'

const DynamicContent = ({ isPhone, t }) => {
  const recentlyViewTitle = hasLocizeTranslation(
    t,
    'HOMEPAGE_RECENTLY_VIEW_TITLE',
    'Recently Viewed',
  )

  return (
    <React.Fragment>
      <EditorialMasonry />
      <EditorialVideos />
      <RecentProductList
        onHomepage
        refType="recentlyviewed-homepage"
        title={recentlyViewTitle}
      />
      <EditorialBestsellers />
      <InfluencerSection isPhone={isPhone} />
    </React.Fragment>
  )
}

DynamicContent.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(DynamicContent)
