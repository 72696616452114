import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import editorialSlidesDuck from '../duck'
import { fetchHeroBanners } from '../utils'

const RetryModal = ({ getHeroBanners, setApiResError, setIsLoading, t }) => (
  <div className="hero-banner__skeleton">
    <div className="hero-banner__error">
      <span className={clsx('hero-banner__error-title', Typo.subtitle1)}>
        {hasLocizeTranslation(
          t,
          'FALL_BACK_MODAL_TITLE',
          'Oops! Something went wrong',
        )}
      </span>
      <span className={clsx('hero-banner__error-subtitle', Typo.body1)}>
        {hasLocizeTranslation(
          t,
          'FALL_BACK_MODAL_CONTENT',
          "We're working on it and we'll get it fixed as soon as we can",
        )}
      </span>
      <Button
        className="hero-banner__error-cta"
        onClick={() =>
          fetchHeroBanners(getHeroBanners, setApiResError, setIsLoading)
        }
        type={BUTTON_TYPES.primary}
      >
        {hasLocizeTranslation(t, 'FALL_BACK_MODAL_CTA_TRY_AGIAN', 'Try Again')}
      </Button>
    </div>
  </div>
)

RetryModal.propTypes = {
  getHeroBanners: PropTypes.func.isRequired,
  setApiResError: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(RetryModal)

export default connect(
  () => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        getHeroBanners: editorialSlidesDuck.creators.get,
      },
      dispatch,
    ),
)(Extended)
