import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import editorialSlidesDuck from './duck'
import SlideContent from './slide-content'
import RetryModal from './retry-modal'
import styles from './style.scss'
import { fetchHeroBanners, updateFirstSliderArrow } from './utils'

const EditorialSlides = ({ getHeroBanners, isPhone, slides }) => {
  const [apiResError, setApiResError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { sliders } = slides || {}

  const shouldShowContent = !apiResError && !isLoading && sliders?.length
  const shouldShowPlaceholder = !apiResError && (isLoading || !sliders)

  useEffect(() => {
    fetchHeroBanners(getHeroBanners, setApiResError, setIsLoading)
  }, [getHeroBanners])

  // finish loading and has no slide available
  if (!isLoading && !sliders?.length) {
    return null
  }

  updateFirstSliderArrow(sliders, isPhone)

  return (
    <div
      className={clsx({
        'slider-media-container-mobile': isPhone,
        'slider-media-container-desktop': !isPhone,
      })}
    >
      <style jsx>{styles}</style>
      {apiResError && (
        <RetryModal
          setApiResError={setApiResError}
          setIsLoading={setIsLoading}
        />
      )}
      {shouldShowPlaceholder && <div className="hero-banner__skeleton" />}
      {shouldShowContent && <SlideContent />}
    </div>
  )
}

EditorialSlides.propTypes = {
  getHeroBanners: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  slides: PropTypes.shape({ sliders: PropTypes.arrayOf(PropTypes.shape({})) }),
}

EditorialSlides.defaultProps = {
  slides: null,
}

export default connect(
  (state) => ({
    isPhone: state.device.isPhone,
    slides: state.editorialSlides.payload,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getHeroBanners: editorialSlidesDuck.creators.get,
      },
      dispatch,
    ),
)(EditorialSlides)
