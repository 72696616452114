import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import NoopLoader from 'components/noop-loader'
import Loader from 'components/loader'
import InfluencersApiHandler from 'lib/api/influencers'
import useIntersection from 'components/custom-hooks/useIntersection'
import Typo from 'constants/typography'

const DynamicConfig = { ssr: false, loading: NoopLoader }

const InfluencerList = dynamic(
  () => import('components/influencer/influencer-list'),
  DynamicConfig,
)

const InfluencerSlider = dynamic(
  () => import('components/influencer/influencer-slider'),
  DynamicConfig,
)

class InfluencerSection extends React.Component {
  state = { list: null }

  async componentDidMount() {
    try {
      const res = await InfluencersApiHandler.getList()

      if (res) {
        this.setState({ list: res.instagrams || [] })
      }
    } catch (e) {
      this.setState({ list: [] })
    }
  }

  render() {
    const { isPhone } = this.props
    const { list } = this.state

    if (!list) {
      return (
        <div className="loader-container">
          <Loader />
        </div>
      )
    }

    if (list.length === 0) {
      return null
    }

    return isPhone ? (
      <InfluencerSlider list={list} />
    ) : (
      <InfluencerList list={list} />
    )
  }
}

const InfluencerSectionSkeleton = ({ isPhone }) => {
  const ref = useRef()
  const isIntersecting = useIntersection(ref, { threshold: 0.1 })

  return (
    <div className="influencer-list" ref={ref}>
      <span
        className={clsx('title', Typo.h4)}
        data-cy="influencer__list__title"
      >
        #pomelogirls
      </span>
      {isIntersecting ? (
        <InfluencerSection isPhone={isPhone} />
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}
    </div>
  )
}

InfluencerSectionSkeleton.propTypes = { isPhone: PropTypes.bool.isRequired }
InfluencerSection.propTypes = { isPhone: PropTypes.bool.isRequired }

export { InfluencerSection }

export default InfluencerSectionSkeleton
