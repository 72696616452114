import StateStorage from '../state-storage'
import { request } from './index'
import { HTTP_METHODS } from './const'

class Influencers {
  static async getList() {
    const language = StateStorage.getLanguage()
    const shop = StateStorage.getShop()
    const cacheKey = `pomeloHomeInstagrams-${shop}-${language}`
    const cached = StateStorage.getLocalState(cacheKey)

    if (cached) {
      return Promise.resolve(cached)
    }

    const res = await request({
      url: 'v6/homepage-instagrams',
      method: HTTP_METHODS.get,
    })

    StateStorage.saveLocalState(cacheKey, res, 60)

    return res
  }
}

export default Influencers
