import PropTypes from 'prop-types'
import React from 'react'
import Dialog from 'components/common/dialog'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import CATEGORY_1 from './const'

const TncModal = ({ close, data, t }) => {
  const renderCustomTerms = (terms) => {
    const termsArr = encodeURI(terms).split('%0D%0A')
    return termsArr.map((term, idx) => <p key={idx}>{decodeURI(term)}</p>)
  }

  const renderCategory1 = () => {
    const TNC1 = hasLocizeTranslation(t, CATEGORY_1.line1Key, CATEGORY_1.line1)
    const TNC2 = hasLocizeTranslation(t, CATEGORY_1.line2Key, CATEGORY_1.line2)
    const TNC3 = hasLocizeTranslation(t, CATEGORY_1.line3Key, CATEGORY_1.line3)
    const TNC4 = hasLocizeTranslation(t, CATEGORY_1.line4Key, CATEGORY_1.line4)
    const TNC5 = hasLocizeTranslation(t, CATEGORY_1.line5Key, CATEGORY_1.line5)
    const TNC6 = hasLocizeTranslation(t, CATEGORY_1.line6Key, CATEGORY_1.line6)
    const TNC7 = hasLocizeTranslation(t, CATEGORY_1.line7Key, CATEGORY_1.line7)
    const TNC8 = hasLocizeTranslation(t, CATEGORY_1.line8Key, CATEGORY_1.line8)

    return (
      <React.Fragment>
        {TNC1 && <p>{TNC1}</p>}
        {TNC2 && <p>{TNC2}</p>}
        {TNC3 && <p>{TNC3}</p>}
        {TNC4 && <p>{TNC4}</p>}
        {TNC5 && <p>{TNC5}</p>}
        {TNC6 && <p>{TNC6}</p>}
        {TNC7 && <p>{TNC7}</p>}
        {TNC8 && <p>{TNC8}</p>}
      </React.Fragment>
    )
  }

  const renderBody = () => {
    if (data && typeof data === 'object' && data.customTerms) {
      return renderCustomTerms(data.customTerms)
    }
    return renderCategory1()
  }

  return (
    <Dialog closable onClose={close}>
      <span className={Typo.h6}>{t('TERMS & CONDITIONS')}</span>
      <div className={Typo.body2}>{renderBody()}</div>
    </Dialog>
  )
}

TncModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    customTerms: PropTypes.string,
    key: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
}

TncModal.defaultProps = {
  data: null,
}

export default withI18next()(TncModal)
