const MOBILE_IMG_RATIO = 1.45
const DESKTOP_IMG_RATIO = 0.5625
const FALL_20_TEASER_CAT_ID = 3910
const FALL_20_DDAY_CAT_ID = 3920

export {
  MOBILE_IMG_RATIO,
  DESKTOP_IMG_RATIO,
  FALL_20_TEASER_CAT_ID,
  FALL_20_DDAY_CAT_ID,
}
