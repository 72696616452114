import PropTypes from 'prop-types'
import React from 'react'
import ResponsiveImage from 'components/images/responsive-image'
import EditorialArtifacts from 'components/editorial/editorial-artifacts'
import { MOBILE_IMG_RATIO } from '../const'
import { onSlideClick, prepareCategoryReferer } from '../utils'

const MobileSlideImages = ({ idx, slide }) => {
  if (!slide.full_image_mobile) {
    return null
  }

  return (
    <div key={slide.image} className="editorial-image-slide-container">
      <ResponsiveImage
        imgRatio={MOBILE_IMG_RATIO}
        src={slide.full_image_mobile}
        onClick={(e) => onSlideClick(e, slide, idx)}
        lazy={!!idx}
        role="button"
        alt="slideImage"
      />
      <div className="editorial-artifacts">
        <EditorialArtifacts
          linkToCategoryCaptured={() => prepareCategoryReferer(idx + 1)}
          {...slide}
        />
      </div>
    </div>
  )
}

MobileSlideImages.propTypes = {
  idx: PropTypes.number.isRequired,
  slide: PropTypes.shape({
    full_image_mobile: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
}

export default MobileSlideImages
