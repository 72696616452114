import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import CommonLayout from 'components/layout/common'
import EditorialSlides from 'components/editorial/editorial-slides'
import Loader from 'components/loader'
import { withI18next } from 'lib/i18n/withI18next'
import NPSFormHandler from 'lib/api/nps'
import BackdropModal from 'components/portal/backdrop-modal'
import NPSForm from 'components/nps'
import styles from './style.scss'
import HomepageContent from './content'
import BasePage from '../base'
import { PAGE_NAME } from '../const'

class Home extends BasePage {
  state = {
    bestsellersActive: false,
    influencersActive: false,
    masonryActive: false,
    npsModalData: {},
    shouldShowNPSModal: false,
    videosActive: false,
    isOmiseIframe: false,
  }

  static async getInitialProps({ store, req, res, isServer }) {
    if (isServer) {
      BasePage.loadInitialContentFromServer({ req, res, store })
    } else {
      BasePage.loadInitialContentFromClient(req, res, store)
    }

    return {}
  }

  afterPageMount() {
    this.checkForNPSDisplay()
    if (window.name === 'omise-3ds') {
      // eslint-disable-next-line no-restricted-globals
      parent.window.postMessage('return from omise 3ds', window.location.href)
      this.setState({ isOmiseIframe: true })
    }
  }

  checkForNPSDisplay() {
    const urlParams = queryString.parse(window.location.search)

    if (urlParams?.em && urlParams?.id_reference && urlParams?.type) {
      NPSFormHandler.check({
        email: urlParams.em,
        id_reference: urlParams.id_reference,
        type: urlParams.type,
      }).then((res) => {
        this.setState({
          shouldShowNPSModal: res?.should_show,
          npsModalData: {
            email: urlParams.em,
            reference: urlParams.id_reference,
            score: urlParams.nps_score,
            type: urlParams.type,
          },
        })
      })
    }
  }

  closeNpsModal = () => {
    this.setState({ npsModalData: {}, shouldShowNPSModal: false })
  }

  shouldComponentUpdate(props, state) {
    // JSON.stringify cannot serialize cyclic structures
    const propsUpdated = this.props.isPhone !== props.isPhone
    const { npsModalData: prevNpsModalData, ...prevState } = this.state
    const { npsModalData, ...newState } = state
    const npsModalDataUpdated = Object.keys(prevNpsModalData).some(
      (key) => prevNpsModalData[key] !== npsModalData[key],
    )

    return (
      propsUpdated ||
      npsModalDataUpdated ||
      JSON.stringify(prevState) !== JSON.stringify(newState)
    )
  }

  renderLoader() {
    return (
      <div className="loader-iframe-home-page">
        <Loader />
      </div>
    )
  }

  renderHomePage() {
    const { isPhone } = this.props
    const { npsModalData, shouldShowNPSModal } = this.state
    const { email, reference, score, type } = npsModalData

    return (
      <CommonLayout
        showAdsBanner
        showSignupBanner
        showCreditsBanner
        pageName={PAGE_NAME.home}
      >
        <EditorialSlides />
        <HomepageContent isPhone={isPhone} />
        <BackdropModal
          onBackdropClick={this.closeNpsModal}
          isOpen={shouldShowNPSModal}
        >
          <NPSForm
            close={this.closeNpsModal}
            email={email}
            reference={reference}
            score={score}
            type={type}
          />
        </BackdropModal>
      </CommonLayout>
    )
  }

  render() {
    const { isOmiseIframe } = this.state
    let bodyJsx = null
    bodyJsx = isOmiseIframe ? this.renderLoader() : this.renderHomePage()
    return (
      <React.Fragment>
        <style jsx>{styles}</style>
        {bodyJsx}
      </React.Fragment>
    )
  }
}

Home.propTypes = {
  isPhone: PropTypes.bool,
}

const Extended = withI18next()(Home)

export { Home }

export default connect((state) => ({
  internationalization: state.internationalization,
  isPhone: state.device.isPhone,
}))(Extended)
