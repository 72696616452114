import Link from 'next/link'
import PropTypes from 'prop-types'
import React from 'react'
import Router from 'next/router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import clsx from 'clsx'
import ProductListSlider from 'components/product/product-list-slider'
import ProductsApiHandler from 'lib/api/products'
import ProductAPiHandler from 'lib/api/product' // remove this one once Beet hero banner is gone
import StateStorage from 'lib/state-storage'
import UrlUtils from 'lib/utils/url'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import duck from '../editorial-slides/duck'

export const EDITORIAL_NO_BUBBLING_CLASS = 'no-bubbling'

class EditorialArtifacts extends React.PureComponent {
  state = {
    productsRes: [],
  }

  async componentDidMount() {
    const {
      enable_associate_product,
      id_category,
      internationalization: i18n,
      isPhone,
      specific_associate_product,
      token,
    } = this.props

    if (isPhone && id_category) {
      if (!enable_associate_product) {
        ProductsApiHandler.getList({
          shopId: i18n.shop,
          categoryId: id_category,
          currencyId: i18n.currency,
          skip: 0,
          limit: 4,
        }).then((res) => {
          if (this.unmounted) return
          this.setState({ productsRes: res && res.products })
        })
      } else if (specific_associate_product) {
        const productIds = specific_associate_product.split(',')
        const isGuestMode = StateStorage.isGuestMode()
        const productsRes = await Promise.all(
          productIds.map(async (id) => {
            const options = {
              token,
              product_id: id.trim(),
              isGuestMode,
            }
            const res = await ProductAPiHandler.getProduct(options)

            if (!res) {
              return {}
            }

            // Map the response from product API to match response from category API
            return {
              category_link_rewrite: res.category && res.category.link_rewrite,
              cover_img_v2:
                res.images && res.images.length && res.images[0].path,
              id_product: res.id_product,
              is_low_quantity: res.stock.is_low_quantity,
              is_sold_out: res.stock.is_sold_out,
              link_rewrite: res.link_rewrite,
              name: res.name,
              price: res.prices && res.prices.original_price,
              price_formatted:
                res.prices && res.prices.original_price_formatted,
              quantity: res.stock.quantity,
              ...(res.prices.reduction && {
                discounted_price: res.prices.discounted_price,
                discounted_price_formatted:
                  res.prices.discounted_price_formatted,
                reduction: res.prices.reduction.amount,
                reduction_type: res.prices.reduction.type,
              }),
            }
          }),
        )

        this.setState({ productsRes })
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  renderCaption(caption, params, className = '') {
    if (!params) {
      return undefined
    }

    const { caption_color, caption_color_mobile, isPhone, title_color } =
      this.props
    const style = {}
    const containerClassName = isPhone ? '' : params.textposition

    if (!isPhone) {
      style.left = params.left
      style.top = params.top
      style.color = title_color || caption_color || null
    } else {
      // Disable caption color for mobile at this time
      style.color = caption_color_mobile || null
    }

    return (
      <span
        className={clsx(containerClassName, className, Typo.h3)}
        style={style}
        dangerouslySetInnerHTML={{ __html: caption }} // eslint-disable-line react/no-danger
      />
    )
  }

  renderCaptions() {
    const { description, description_info, title, title_info } = this.props
    return (
      <React.Fragment>
        {this.renderCaption(title, title_info, 'title')}
        {this.renderCaption(description, description_info, 'description')}
      </React.Fragment>
    )
  }

  renderButton = (data) => {
    const { isPhone, linkToCategoryCaptured } = this.props
    let buttonStyle = {
      backgroundColor: data.cta_box_color || 'black',
      color: data.cta_caption_color || 'white',
      top: data.cta_info.top || '0',
      left: data.cta_info.left || '0',
    }

    if (isPhone) {
      buttonStyle = { ...buttonStyle, left: '', top: '' }
    } else if (data && data.cta_info) {
      switch (data.cta_info.textposition) {
        case 'rightcentered':
          buttonStyle = { ...buttonStyle, left: 'calc(75% - 120px)' }
          break
        case 'leftcentered':
          buttonStyle = { ...buttonStyle, left: 'calc(25% - 120px)' }
          break
        case 'centered':
          buttonStyle = {
            ...buttonStyle,

            left: '0',
            right: '0',
            marginLeft: 'auto',
            marginRight: 'auto',
          }
          break
        default:
          break
      }
    }

    if (data.cta_link) {
      const linkToExpectedPage = `${data.cta_link}?ref_type=home_slider`

      return (
        <Link
          as={linkToExpectedPage}
          key={data.id_cta}
          href={linkToExpectedPage}
        >
          <a
            onClickCapture={
              data.is_category_page ? linkToCategoryCaptured : null
            }
          >
            <Button
              className={clsx('hero-banner-cta', EDITORIAL_NO_BUBBLING_CLASS)}
              style={buttonStyle}
              type={BUTTON_TYPES.primary}
            >
              {data.cta}
            </Button>
          </a>
        </Link>
      )
    }

    return null
  }

  renderAction() {
    const { ctas } = this.props

    if (!ctas) {
      return undefined
    }

    return ctas.map(this.renderButton)
  }

  getProductUrl(product) {
    if (product.product_url) {
      return UrlUtils.getLocation(product.product_url).pathname
    }

    const shop = StateStorage.getShop()
    const language = StateStorage.getLanguage()
    const productId = product.id_product || product.id_product_2

    if (productId) {
      return `/${shop}/${language}/${product.category_link_rewrite}/${productId}-${product.link_rewrite}.html`
    }

    return '#'
  }

  goToProduct(e, product) {
    e.preventDefault()
    e.stopPropagation()

    Router.push(
      `/product?id=${product.id_product || product.id_product_2}`,
      this.getProductUrl(product),
    )
  }

  renderProduct = (product) => {
    if (!product.tag || !product.tag.on) {
      return undefined
    }

    const style = {
      top: product.tag.top,
      left: product.tag.left,
    }

    return (
      <span
        key={product.id_product}
        style={style}
        className="artifact-tooltip artifact-tooltip-visible"
        onClick={(e) => this.goToProduct(e, product)}
        onKeyDown={(e) => this.goToProduct(e, product)}
        role="button"
        tabIndex={0}
      >
        <em className="artifact-tooltip-title">{product.tag.name}</em>
        <em className="artifact-tooltip-price">{product.formatted_price}</em>

        {/* TODO: checking this component in the future that we still using it or not */}
        {/* <em className="icon icon-arrow-right">&nbsp;</em> */}
      </span>
    )
  }

  render() {
    const { id_category, isPhone, position, products } = this.props
    const { productsRes } = this.state
    const hasProducts = !productsRes || productsRes.length > 0

    return isPhone ? (
      <div className="editorial-image-artifacts-mobile">
        {this.renderCaptions()}
        <div
          className="render-button"
          style={hasProducts ? null : { top: '70%' }}
        >
          {this.renderAction()}
        </div>
        {hasProducts && (
          <div className="editorial__product-list">
            <ProductListSlider
              displayBadges={false}
              displayBullets={false}
              displayDescription
              list={productsRes}
              refId={id_category}
              refType="home_sliders"
              subPosition={position}
              isPhone
            />
          </div>
        )}
      </div>
    ) : (
      <div className="editorial-image-artifacts">
        {this.renderCaptions()}
        {this.renderAction()}
        {products.map(this.renderProduct)}
      </div>
    )
  }
}

EditorialArtifacts.defaultProps = {
  caption_color: '',
  caption_color_mobile: '',
  description_info: null,
  enable_associate_product: false,
  products: [],
  specific_associate_product: '',
  title_color: '',
  title_info: null,
  title: undefined,
  description: undefined,
}

EditorialArtifacts.propTypes = {
  caption_color: PropTypes.string,
  caption_color_mobile: PropTypes.string,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      cta_box_color: PropTypes.string,
      cta_caption_color: PropTypes.string,
      cta_info: PropTypes.shape({
        fontsize: PropTypes.string,
        left: PropTypes.string,
        textposition: PropTypes.string,
        top: PropTypes.string,
      }),
    }),
  ).isRequired,
  description: PropTypes.string,
  description_info: PropTypes.oneOfType([
    PropTypes.shape({
      fontsize: PropTypes.string,
      left: PropTypes.string,
      textposition: PropTypes.string,
      top: PropTypes.string,
    }),
    PropTypes.shape({
      bottomcolor: PropTypes.bool,
      bottomfontsize: PropTypes.bool,
      fontsize: PropTypes.bool,
      left: PropTypes.bool,
      textalign: PropTypes.bool,
      textposition: PropTypes.bool,
      top: PropTypes.bool,
    }),
  ]),
  enable_associate_product: PropTypes.number,
  id_category: PropTypes.number.isRequired,
  internationalization: PropTypes.shape({
    shop: PropTypes.number,
    currency: PropTypes.number,
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
  linkToCategoryCaptured: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  specific_associate_product: PropTypes.string,
  title: PropTypes.string,
  title_color: PropTypes.string,
  title_info: PropTypes.oneOfType([
    PropTypes.shape({
      fontsize: PropTypes.string,
      left: PropTypes.string,
      textposition: PropTypes.string,
      top: PropTypes.string,
    }),
    PropTypes.shape({}),
  ]),
  token: PropTypes.string.isRequired,
}

export { EditorialArtifacts }

export default connect(
  (state) => ({
    internationalization: state.internationalization,
    isPhone: state.device.isPhone,
    slides: state.editorialSlides.payload,
    token: state.auth.token,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getSlides: duck.creators.get,
        reset: duck.creators.invalidate,
      },
      dispatch,
    ),
)(EditorialArtifacts)
