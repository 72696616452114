import StateStorage from '../state-storage'
import { request } from './index'

class Editorial {
  static async getSlides() {
    return request({
      url: 'v6/homepage-sliders',
    })
  }

  static async getMasonry() {
    const language = StateStorage.getLanguage()
    const shop = StateStorage.getShop()
    const keyName = `pomeloEditorialMasonry-${shop}-${language}`
    const cached = StateStorage.getLocalState(keyName)

    if (cached) {
      return Promise.resolve(cached)
    }

    const res = await request({
      url: 'v6/homepage-masonry',
    })

    StateStorage.saveLocalState(keyName, res)
    return res
  }

  static async getVideos() {
    const language = StateStorage.getLanguage()
    const shop = StateStorage.getShop()
    const keyName = `pomeloEditorialVideos-${shop}-${language}`
    const cached = StateStorage.getLocalState(keyName)

    if (cached) {
      return Promise.resolve(cached)
    }

    const res = await request({
      url: 'v6/homepage-videos/',
    })

    StateStorage.saveLocalState(keyName, res)
    return res
  }
}

export default Editorial
