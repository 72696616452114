import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Swiper from 'react-id-swiper'
import { connect } from 'react-redux'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Icon from 'components/common/icon'
import DesktopSlideImages from '../desktop-slide-images'
import MobileSlideImages from '../mobile-slide-images'
import { realIndexChange } from '../utils'

const renderIcon = (alt, className, src) => (
  <Icon
    className={className}
    src={ICONS[src]}
    size={ICON_SIZE.medium}
    alt={alt}
  />
)

const SlideContent = ({ isPhone, slides }) => {
  const swiperRef = useRef(null)
  const { sliders } = slides || {}

  // slider options
  const sliderOptions = {
    slidesPerView: 1,
    loop: false,
    spaceBetween: 5,
    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
    noSwiping: !isPhone,
    autoplay: {
      delay: 7000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    renderPrevButton: () =>
      renderIcon('swiperPrevButton', 'swiper-button-prev', 'arrowHeadLeft'),
    renderNextButton: () =>
      renderIcon('swiperNextButton', 'swiper-button-next', 'arrowHeadRight'),
    disableOnInteraction: true,
    on: {
      // https://spectrum.chat/react-id-swiper/general/how-to-get-current-index~2ddf34c1-4cc7-4d15-8236-0518c0fc7ac7
      // https://github.com/kidjp85/react-id-swiper/issues/490#issuecomment-817154721
      realIndexChange: () => realIndexChange(sliders, swiperRef),
    },
    ...(sliders?.length > 1 && {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }),
  }

  return isPhone ? (
    <div className="editorial-slides-mobile">
      {sliders.map((slide, idx) => (
        <MobileSlideImages key={idx} slide={slide} idx={idx} />
      ))}
    </div>
  ) : (
    <Swiper {...sliderOptions} ref={swiperRef}>
      {sliders.map((slide, idx) => (
        <DesktopSlideImages key={idx} slide={slide} idx={idx} />
      ))}
    </Swiper>
  )
}

SlideContent.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  slides: PropTypes.shape({ sliders: PropTypes.arrayOf(PropTypes.shape({})) }),
}

SlideContent.defaultProps = {
  slides: null,
}

export default connect((state) => ({
  isPhone: state.device.isPhone,
  slides: state.editorialSlides.payload,
}))(SlideContent)
