import Router from 'next/router'
import { SEGMENT_STORAGE_KEY_CATEGORY } from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import { EDITORIAL_NO_BUBBLING_CLASS } from '../editorial-artifacts'
import { FALL_20_TEASER_CAT_ID, FALL_20_DDAY_CAT_ID } from './const'

const updateArrowClass = (arrowClass, invertField) => {
  const arrow = document.querySelector(arrowClass)

  if (invertField) {
    arrow.classList.add('white')
  } else {
    arrow.classList.remove('white')
  }
}

const fetchHeroBanners = async (
  getHeroBanners,
  setApiResError,
  setIsLoading,
) => {
  const res = await getHeroBanners({ ignoreCache: true })
  setApiResError(!!res?.error)
  setIsLoading(false)
}

const realIndexChange = (sliders, swiperRef) => {
  const { realIndex } = swiperRef.current.swiper

  // realIndex should start with 0 if not return to avoid crash
  if (realIndex > sliders.length - 1) {
    return
  }

  updateArrowClass(
    '.swiper-button-next',
    sliders[realIndex].invert_right_arrow_color,
  )
  updateArrowClass(
    '.swiper-button-prev',
    sliders[realIndex].invert_left_arrow_color,
  )
}

const updateFirstSliderArrow = (sliders, isPhone) => {
  const validSlideOnDesktop = sliders?.length && !isPhone

  // hack to change swiper color of the first sliders
  if (validSlideOnDesktop && sliders[0].invert_right_arrow_color) {
    const nextBtn = document.querySelector('.swiper-button-next')
    nextBtn?.classList.add('white')
  }

  if (validSlideOnDesktop && sliders[0].invert_left_arrow_color) {
    const prevBtn = document.querySelector('.swiper-button-prev')
    prevBtn?.classList.add('white')
  }
}

const prepareCategoryReferer = (idx) => {
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.id, '')
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.position, idx)
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.type, 'home_slider')
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos, '')
}

const getCategoryRefererData = () => {
  // id_category couldn't set for hero banners
  const clickPosition = sessionStorage.getItem(
    SEGMENT_STORAGE_KEY_CATEGORY.position,
  )
  const refId = sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.id)
  const refType = sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.type)
  const subPosition = sessionStorage.getItem(
    SEGMENT_STORAGE_KEY_CATEGORY.subPos,
  )

  return {
    clickPosition,
    refId,
    refType,
    subPosition,
  }
}

const getAsPath = (as) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  return `/${shop}/${language}/${as}`
}

const getCategoryPath = (slide) => {
  const categoryPath = slide.content_link
  const { clickPosition, refId, refType, subPosition } =
    getCategoryRefererData()

  const href = categoryPath.includes('perks')
    ? categoryPath
    : `${categoryPath}?click_position=${clickPosition}&ref_id=${refId}&ref_type=${refType}&sub_position=${subPosition}`

  return href
}

const getSlideLink = (slide) => {
  const href = slide.id_category ? `/category?id=${slide.id_category}` : ''
  const link = {
    as: href,
    href,
  }

  if (slide.id_category === FALL_20_TEASER_CAT_ID) {
    link.href = '/lookbook?id=3910'
    link.as = getAsPath('lookbooks/fall-collection-teaser')
  } else if (slide.id_category === FALL_20_DDAY_CAT_ID) {
    link.href = '/lookbook?id=3920'
    link.as = getAsPath('lookbooks/fall20')
  } else if (slide.content_link) {
    delete link.as
    link.href = getCategoryPath(slide)
  }

  return link
}

const onSlideClick = (e, slide, idx) => {
  if (e?.target?.parentNode?.classList?.contains(EDITORIAL_NO_BUBBLING_CLASS)) {
    return
  }

  prepareCategoryReferer(idx + 1)

  const link = getSlideLink(slide)

  if (link.as) {
    Router.push(link.href, link.as, { shallow: true })
  } else {
    Router.push(link.href)
  }
}

export {
  fetchHeroBanners,
  onSlideClick,
  prepareCategoryReferer,
  realIndexChange,
  updateFirstSliderArrow,
}
