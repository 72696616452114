import PropTypes from 'prop-types'
import React from 'react'
import Router from 'next/router'
import Link from 'next/link'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import clsx from 'clsx'
import EditorialApiHandler from 'lib/api/editorial'
import ResponsiveImage from 'components/images/responsive-image'
import StateStorage from 'lib/state-storage'
import UrlUtils from 'lib/utils/url'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { SEGMENT_STORAGE_KEY_CATEGORY } from 'lib/segment/const'
import Typo from 'constants/typography'
import env from 'config/env'
import BackdropModal from 'components/portal/backdrop-modal'
import TncModal from 'components/modal/tnc-modal'
import styles from './style.scss'

class EditorialMasonry extends React.PureComponent {
  MASONRY_WITH_MODAL = []

  state = {
    list: [],
    tncModalData: null,
  }

  staticTimeout = null

  componentDidMount() {
    EditorialApiHandler.getMasonry().then((res) =>
      this.setState({ list: res.masonry || [] }),
    )

    this.animateStaticUsp()
  }

  componentWillUnmount() {
    clearTimeout(this.staticTimeout)
  }

  animateStaticUsp() {
    const elem = document.getElementById('static-animated-usp')

    if (!elem) return

    this.loopStaticUspElement(elem, 0)
  }

  loopStaticUspElement(container, index) {
    const { length } = container.childNodes

    if (index > length - 1) {
      Array.from(container.querySelectorAll('.h4')).map((el) =>
        el.classList.remove('visible'),
      )
      this.staticTimeout = setTimeout(
        () => this.loopStaticUspElement(container, 0),
        1000,
      )
    } else {
      const elem = container.childNodes[index]
      elem.classList.add('visible')
      this.staticTimeout = setTimeout(
        () => this.loopStaticUspElement(container, index + 1),
        1000,
      )
    }
  }

  getImageUrl(imageId) {
    const imgHost = env.IMG_HOST
    const imageFullPath = `${imgHost}img/homepage/masonry/${imageId}`

    return `${imageFullPath}`
  }

  // A modificated version of the one inside category-item component.
  getCategoryHref(inputLink) {
    if (
      !inputLink ||
      (!inputLink.startsWith('/clothes/') &&
        !inputLink.startsWith('/features/'))
    ) {
      return null
    }

    let link = inputLink

    if (!link.endsWith('/')) {
      link += '/'
    }

    const linkParts = link.split('/')
    return `/category?id=${linkParts[linkParts.length - 2]}`
  }

  onItemClick(item, idx) {
    const { content_link, id_category } = item
    const lastFragment = content_link ? content_link.split('/').pop() : null

    if (this.MASONRY_WITH_MODAL.includes(lastFragment)) {
      this.setState({ tncModalData: { key: lastFragment } })

      return null
    }

    const urlContent = UrlUtils.getLocation(content_link)
    if (urlContent) {
      const url = content_link ? urlContent.pathname : null
      const href = id_category ? `/category?id=${id_category}` : url
      const shop = StateStorage.getShop()
      const lang = StateStorage.getLanguage()
      const link = {
        as: `/${shop}/${lang}${url}`,
        href:
          url && id_category
            ? `/category?id=${UrlUtils.getPathWithoutLocale(url)}`
            : this.getCategoryHref(href) || url,
      }

      sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.id, '')
      sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.position, idx)
      sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.type, 'home_masonry')
      sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos, '')

      Router.push(link.href, link.as)
    }
    return undefined
  }

  clearTncModalData = () => {
    this.setState({ tncModalData: null })
  }

  renderImage(item) {
    const { isPhone } = this.props
    const image = isPhone
      ? `${item.image_mobile}_mobile.jpg`
      : `${item.image}_desktop.jpg`

    return (
      <ResponsiveImage
        src={this.getImageUrl(image)}
        alt="Pomelo"
        lazy={false}
        ratio={1}
      />
    )
  }

  renderItem = (item, idx) => {
    const { isPhone } = this.props
    const color = isPhone ? item.color_mobile : item.color
    const style = { color }

    return (
      <div
        key={item.id_homepage_masonry}
        className="editorial-masonry-item-container"
        data-cy={`editorial__mansonry__item_${idx}`}
        onClick={() => this.onItemClick(item, idx + 1)}
        onKeyDown={() => this.onItemClick(item, idx + 1)}
        role="presentation"
      >
        <div className="editorial-masonry-item-square-box">
          <div className="editorial-masonry-item-square-box-content">
            <div className="editorial-masonry-image-container">
              {this.renderImage(item, isPhone)}
              <div className="title" style={style}>
                {item?.titles.map((title) => {
                  if (title.info?.on) {
                    return (
                      <span className={Typo.h3} key={title.text}>
                        {title.text}
                      </span>
                    )
                  }
                  return null
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderStaticUspBanner() {
    const {
      lazy,
      internationalization: { country, language },
      t,
    } = this.props
    const line1 = hasLocizeTranslation(t, 'MASONRY_STATIC_USP_1', '').trim()
    const line2 = hasLocizeTranslation(t, 'MASONRY_STATIC_USP_2', '').trim()
    const line3 = hasLocizeTranslation(t, 'MASONRY_STATIC_USP_3', '').trim()
    const line4 = hasLocizeTranslation(t, 'MASONRY_STATIC_USP_4', '').trim()
    const line5 = hasLocizeTranslation(t, 'MASONRY_STATIC_USP_5', '').trim()

    const as = `/${country}/${language}/clothes/new-arrivals`
    const href = '/category?id=88'

    return (
      !lazy && (
        <Link as={as} href={href}>
          <a
            className={clsx(
              Typo.h6,
              'editorial-masonry-item-container',
              'static-usp',
            )}
            id="static-animated-usp"
            data-cy="static__animated__usp"
          >
            {!!line1 && <span className="static-usp__text">{line1}</span>}
            {!!line2 && <span className="static-usp__text">{line2}</span>}
            {!!line3 && <span className="static-usp__text">{line3}</span>}
            {!!line4 && <span className="static-usp__text">{line4}</span>}
            {!!line5 && <span className="static-usp__text">{line5}</span>}
          </a>
        </Link>
      )
    )
  }

  render() {
    const { isPhone, lazy } = this.props
    const { list, tncModalData } = this.state

    return (
      <div
        className={clsx('editorial-masonry-container', {
          'ttbh-masonry': lazy,
        })}
      >
        <style jsx>{styles}</style>
        {this.renderStaticUspBanner()}
        {(!lazy ? list : list.slice(0, 2)).map(this.renderItem)}
        <BackdropModal
          freezeBody={isPhone}
          isOpen={!!tncModalData}
          onBackdropClick={this.clearTncModalData}
        >
          <TncModal data={tncModalData} close={this.clearTncModalData} />
        </BackdropModal>
      </div>
    )
  }
}

EditorialMasonry.defaultProps = {
  lazy: false,
}

EditorialMasonry.propTypes = {
  lazy: PropTypes.bool,
  internationalization: PropTypes.shape({
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export { EditorialMasonry }

const Extended = translate('global')(EditorialMasonry)

export default connect(
  /* istanbul ignore next */
  (state) => ({
    internationalization: state.internationalization,
    isPhone: state.device.isPhone,
  }),
)(Extended)
