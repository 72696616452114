import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import EditorialApiHandler from 'lib/api/editorial'
import VideoPlayer from 'components/video-player'
import styles from './style.scss'

const EditorialVideos = ({ isPhone }) => {
  const [videos, setVideos] = React.useState([])

  const fetchVideos = React.useCallback(async () => {
    const response = await EditorialApiHandler.getVideos()
    setVideos(response?.videos || [])
  }, [])

  React.useEffect(() => {
    fetchVideos()
  }, [fetchVideos])

  if (!videos?.length) {
    return null
  }

  return (
    <div className="editorial-videos-container">
      <style jsx>{styles}</style>
      {videos.map(({ full_video, full_image, full_image_mobile }) => (
        <VideoPlayer
          key={full_video}
          cover={isPhone ? full_image_mobile : full_image}
          fullscreenOnPlay={isPhone}
          hideControls={isPhone}
          loop
          muted={false}
          source={full_video}
        />
      ))}
    </div>
  )
}

EditorialVideos.propTypes = { isPhone: PropTypes.bool.isRequired }

const Extended = translate('global')(EditorialVideos)

export { EditorialVideos }

export default connect((state) => ({ isPhone: state.device.isPhone }))(Extended)
