import PropTypes from 'prop-types'
import React from 'react'
import ResponsiveImage from 'components/images/responsive-image'
import EditorialArtifacts from 'components/editorial/editorial-artifacts'
import { DESKTOP_IMG_RATIO } from '../const'
import { onSlideClick, prepareCategoryReferer } from '../utils'

const DesktopSlideImages = ({ idx, slide }) => {
  if (!slide.full_image) {
    return null
  }

  return (
    <div
      key={slide.image}
      className="swiper-slide relative editorial-image-slide-container"
      onClick={(e) => onSlideClick(e, slide, idx)}
      onKeyUp={(e) => onSlideClick(e, slide, idx)}
      role="button"
      tabIndex="0"
    >
      <ResponsiveImage
        className="editorial-images-slide-image-background"
        imgRatio={DESKTOP_IMG_RATIO}
        src={slide.full_image}
        lazy
        alt="slideImage"
      />
      <div className="editorial-artifacts">
        <EditorialArtifacts
          linkToCategoryCaptured={() => prepareCategoryReferer(idx + 1)}
          {...slide}
        />
      </div>
    </div>
  )
}

DesktopSlideImages.propTypes = {
  idx: PropTypes.number.isRequired,
  slide: PropTypes.shape({
    full_image: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
}

export default DesktopSlideImages
