import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import clsx from 'clsx'
import { hasLocizeTranslation } from 'lib/utils/locize'
import useIntersection from 'components/custom-hooks/useIntersection'
import useBestsellers from 'components/shared/useSWR/useBestsellers'
import ProductList from 'components/product/product-list'
import ProductListSkeleton from 'components/product/product-list-skeleton'
import Typo from 'constants/typography'
import styles from './style.scss'

const EditorialBestsellers = ({ setIsContainerShown }) => {
  const { data: firstList, error: firstListError } = useBestsellers(6) // tops
  const { data: secondList, error: secondListError } = useBestsellers(7) // skirts
  const { data: thirdList, error: thirdListError } = useBestsellers(36) // dresses

  if (
    (firstListError || firstList?.length === 0) &&
    (secondListError || secondList?.length === 0) &&
    (thirdListError || thirdList?.length === 0)
  ) {
    setIsContainerShown(false)
  }

  return (
    <React.Fragment>
      {firstList?.length > 0 ? (
        <ProductList
          displayDescription
          displayPrice
          displaySwatches
          displayBadges
          displayManufacturerName
          displayWishlistIcon
          displayHoverInteractions
          list={firstList}
          refId=""
          refType="home_best_sellers"
          promptLocation="wishlist_appfeed"
        />
      ) : (
        <ProductListSkeleton />
      )}
      {secondList?.length > 0 ? (
        <ProductList
          displayDescription
          displayPrice
          displayBadges
          displayManufacturerName
          displayWishlistIcon
          displayHoverInteractions
          displaySwatches
          list={secondList}
          refId=""
          refType="home_best_sellers"
          promptLocation="wishlist_appfeed"
        />
      ) : (
        <ProductListSkeleton />
      )}
      {thirdList?.length > 0 ? (
        <ProductList
          displayDescription
          displayPrice
          displayBadges
          displayManufacturerName
          displayWishlistIcon
          displayHoverInteractions
          displaySwatches
          list={thirdList}
          refId=""
          refType="home_best_sellers"
          promptLocation="wishlist_appfeed"
        />
      ) : (
        <ProductListSkeleton />
      )}
    </React.Fragment>
  )
}

const EditorialBestsellersSkeleton = ({ t }) => {
  const [isContainerShown, setIsContainerShown] = useState(true)

  const ref = useRef()
  const isIntersecting = useIntersection(ref, { threshold: 0.1 })

  if (!isContainerShown) {
    return null
  }

  return (
    <div className="editorial-bestsellers-container" ref={ref}>
      <style jsx>{styles}</style>
      <span
        className={clsx('title', Typo.h4)}
        data-cy="editorial__bestsellers__title"
      >
        {hasLocizeTranslation(t, 'Best Sellers', 'Best Sellers')}
      </span>
      {isIntersecting && (
        <EditorialBestsellers setIsContainerShown={setIsContainerShown} />
      )}
    </div>
  )
}

EditorialBestsellers.propTypes = {
  setIsContainerShown: PropTypes.func,
}

EditorialBestsellers.defaultProps = {
  setIsContainerShown: undefined,
}

EditorialBestsellersSkeleton.propTypes = {
  t: PropTypes.func.isRequired,
}

const Extended = translate('global')(EditorialBestsellersSkeleton)

export default connect((state) => ({
  internationalization: state.internationalization,
  isPhone: state.device.isPhone,
}))(Extended)
