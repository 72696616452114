import { request } from 'lib/api'
import { HTTP_METHODS } from './const'

class NPS {
  static check(options) {
    if (!options.email || !options.id_reference || !options.type) {
      return null
    }

    const { email, id_reference: idReference, type } = options

    return request({
      method: HTTP_METHODS.post,
      url: 'v6/nps/validate',
      body: {
        email,
        id_reference: +idReference,
        type,
      },
    })
  }

  static saveScore(body) {
    return request({
      method: HTTP_METHODS.post,
      body,
      url: 'v6/nps',
    })
  }

  static saveComment({ score, comment, NPSID }) {
    return request({
      method: HTTP_METHODS.post,
      url: `v6/nps/${NPSID}`,
      body: {
        score,
        comment,
      },
    })
  }
}

export default NPS
